@use "src/styles/typography";

.nav-bar {
  position: fixed;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  left: 0;
  top: 0;
  bottom: 0;
  right: auto;

  height: calc(100% - 120px);
  width: calc(18% - 60px);

  padding: 30px 30px 90px 30px;

  background: var(--gradient-blue);

  &--main {
    display: flex;
    flex-direction: column;

    gap: 62px;
  }

  &--header {
    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 2px;
    width: 100%;
  }

  &--footer {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;

    width: 100%;

    gap: 12px;

    path {
      stroke: var(--color-white);
    }
  }

  &--heading {
    @include typography.ordinary;

    display: inline;
    word-wrap: break-word;

    color: var(--color-white);
  }

  &--job-info {
    @include typography.description;

    width: 100%;

    color: var(--color-white);
  }

  &--user-main-info {
    display: flex;
    flex-direction: row;

    gap: 16px;

    align-items: center;

    width: 100%;
  }

  &--list {
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    padding: 12px;
    margin: 0;

    &--item {
      display: flex;

      align-items: center;
      gap: 16px;

      list-style: none;
      padding: 8px 0;

      > svg, path {
        max-width: 24px;
        max-height: 24px;
        stroke: var(--color-white);
      }
    }

    &--link {
      @include typography.page_subtitle;
      text-decoration: none;

      color: var(--color-white);
    }
  }
}