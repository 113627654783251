@use "css-variables";

// Figma name: Заголовки главной страницы
@mixin main_heading {
  //font-size: functions.flex-font-size(36); //base: 54px
  font-size: 36px; //base: 54px
  font-weight: 700;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}
// Figma name: Подзаголовки главной (и авторизации)
@mixin main_subtitle {
  //font-size: functions.flex-font-size(28); //base: 28px
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Заголовки страниц
@mixin page_head {
  //font-size: functions.flex-font-size(24);  //base: 26px
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Подзаголовки страниц
@mixin page_subtitle {
  //font-size: functions.flex-font-size(18); //base: 20px
  font-size: 20px; //base: 20px
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Обычный текст
@mixin ordinary {
  //font-size: functions.flex-font-size(15); //base: 17px
  font-size: 17px; //base: 17px
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Подзаголовки к обычному тексту
@mixin ordinary_subtitle {
  //font-size: functions.flex-font-size(14); //base: 16px
  font-size: 16px; //base: 16px
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Описание
@mixin description {
  //font-size: functions.flex-font-size(12); //base: 14px
  font-size: 14px; //base: 14px
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Подписи, Начертание
@mixin signature {
  //font-size: functions.flex-font-size(10); //base: 12px
  font-size: 12px; //base: 12px
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Текст кнопки (big)
@mixin button_big {
  font-size: 16px; //base: 16px
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Текст кнопки (middle)
@mixin button_middle {
  font-size: 14px; //base: 14px
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}

// Figma name: Текст кнопки (small)
@mixin button_small {
  font-size: 12px; //base: 12px
  font-weight: 500;
  font-style: normal;
  line-height: 1.2;
  color: var(--color-derk-text);
}