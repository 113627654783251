@use "src/styles/variables";
@use "src/styles/typography";

.select {
  & {
    @include typography.ordinary_subtitle;
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px;
  gap: 4px;

  margin: 0;
  border: 1px solid var(--color-gray);
  border-radius: 10px;

  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:not([multiple]) {
    background-repeat: no-repeat;
    background-position: calc(100% - 0.25em) calc(100% / 2);
    background-size: 28px auto;
    background-image: url("../../static/images/chevron-down.svg");
  }
  &:not([multiple]):focus {
    background-repeat: no-repeat;
    background-position: calc(100% - 0.25em) calc(100% / 2);
    background-size: 28px auto;
    background-image: url("../../static/images/chevron-up.svg");
  }

  &:focus {
    border: 1px solid var(--color-blue);
  }

  &.fullWidth {
    width: 100%;
  }

  &.lowSize {
    @include typography.description;
    padding: 12px;
  }

  &__option {
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    padding: 12px;

    border: 1px solid var(--color-gray);
  }
}