@use "src/styles/variables";
@use "src/styles/typography";

.input {
  $this: &;

  display: block;
  max-width: 366px;

  &--full-width {
    width: 100%;
    max-width: none;
  }

  &--container {
    @include typography.ordinary_subtitle;
    display: flex;
    flex-direction: row;

    align-items: center;

    border: 1px solid var(--color-gray);
    border-radius: 10px;

    background-color: var(--color-white);

    &--warning {
      border: 1px solid var(--color-red);
    }
    &--full-width {
      width: 100%;
    }

    #{$this}:hover & {
      border: 1px solid var(--color-blue);
      cursor: text;
    }
    #{$this}:focus-within & {
      border: 1px solid var(--color-blue);
    }
  }

  &--select-from {
    @include typography.ordinary_subtitle;
    color: var(--color-dark-text);
    width: 100%;
    height: 100%;

    border: 0;
    outline: none;

    padding: 16px;
    border-radius: inherit;

    &.low-size {
      @include typography.description;
      padding: 12px;
    }
  }

  &--after-icon {
    display: flex;
    align-items: center;

    cursor: pointer;
    margin-right: 16px;

    user-select: none;

    > svg {
      max-width: 18px;
      max-height: 18px;
    }
  }

  &--label-block {
    margin-bottom: 4px;
  }

  &--label {
    @include typography.ordinary_subtitle;
    color: var(--color-dark-text);
    text-align: start;
    margin-bottom: 2px;
  }

  &--warning {
    display: flex;
    flex-direction: row;

    align-items: center;
    margin: 4px 0 0 2px;
    gap: 4px;

    width: 100%;

    > svg {
      max-width: 16px;
      max-height: 16px;
    }
    > svg, path {
      fill: var(--color-red);
    }
  }

  &--label-warning {
    @include typography.signature;
    color: var(--color-red);
  }
}