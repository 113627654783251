@import "~@csstools/normalize.css";
@import "styles/css-variables.scss";
@import "styles/fonts";

body {
  margin: 0;

  background-color: var(--color-gray-light);

  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
select,
textarea {
  font-family: "Rubik", sans-serif;
}

select,
button {
  cursor: pointer;

  font-style: normal;

&:disabled {
   cursor: not-allowed;
 }
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
p {
  margin: 0;

  font-style: normal;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

#root {
  display: flex;

  flex-direction: column;

  min-height: 100vh;
}

.container {
  padding: 0 209px;
}

.page-container {
  margin-left: 18%;
  padding: 30px;
}
