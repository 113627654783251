@use "styles/typography";

.authorization {
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;
  text-align: center;
  gap: 16px;
  max-width: 410px;

  &--header {
    display: flex;
    flex-direction: row;

    align-self: center;

    width: 100%;

    h2 {
      @include typography.page_subtitle;

      width: 100%;
      color: var(--color-dark-text);
    }
  }

  &--form {
    display: flex;
    flex-direction: column;

    gap: 12px;

    width: 100%;
  }
  &--inputs {
    display: flex;
    flex-direction: column;

    gap: 18px;

    width: 100%;
  }

  &--actions {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    gap: 24px;

    &--item {
      @include typography.ordinary_subtitle;

      cursor: pointer;

      color: var(--color-blue);
    }
  }

  &-footer {
    @include typography.description;

    color: var(--color-gray-text);

    u {
      cursor: pointer;
    }
  }
}

.close-image {
  position: absolute;
  right: 20px;
  cursor: pointer;
}