@use "src/styles/typography";

.support {
  position: fixed;
  bottom: 20px;
  right: 20px;

  &--wrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    cursor: pointer;

    gap: 8px;

    svg {
      max-width: 60px;
    }
  }

  &--heading {
    @include typography.ordinary;
  }
}

.chat {
  &--wrapper {
    display: flex;
    flex-direction: column;

    width: 320px;
    height: 400px;
    padding: 12px;

    border-radius: 12px;
    border: 1px solid var(--color-blue);
    background-color: var(--color-white);
  }

  &--header {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid var(--color-gray);

    #click, svg {
      max-width: 20px;
      cursor: pointer;
    }
  }

  &--list {
    display: flex;
    flex-direction: column;

    padding: 20px;
    gap: 8px;

    overflow-y: auto;
  }

  &--footer {
    display: flex;
    align-items: center;

    gap: 20px;

    margin-bottom: 0;
    margin-top: auto;
  }

  &--heading {
    @include typography.ordinary;
  }
}

.message-support {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;

  svg {
    max-width: 30px;
  }

  &--info {
    display: flex;
    flex-direction: column;

    gap: 2px;
  }

  &--description {
    @include typography.description;
  }
  &--heading {
    @include typography.ordinary
  }

  &--user-header {
    display: flex;

    align-items: center;
    gap: 8px;
  }

  &--content {
    @include typography.ordinary;
    color: var(--color-dark-text);
  }

  &--header {
    position: fixed;

    width: calc(100% - 80px);
    padding: 20px 40px;

    background: var(--color-gray-light);

    border-bottom: 1px solid var(--color-gray);
  }

  &--footer {
    display: flex;

    align-items: center;

    gap: 20px;
    @extend .message-support--header;

    bottom: 0;
    border-top: 1px solid var(--color-gray);

    #click {
      cursor: pointer;
    }
  }
}
