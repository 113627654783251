@import "src/styles/variables.scss";

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

}

.bg-closer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: var(--color-gray-modal);
  cursor: pointer;
}

.modal-card {
  position: relative;
  overflow-y: auto;
  max-height: 930px;
  max-width: 480px;

  border-radius: 10px;
  background: var(--color-white);

  padding: 20px 28px;

  z-index: 12;
}
