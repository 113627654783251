@use "variables";

:root {
  --color-dark-text: #{variables.$color__black-text};
  --color-gray-text: #{variables.$color__gray-text};

  --color-gray: #{variables.$color__gray};
  --color-gray-light: #{variables.$color__gray-light};
  --color-gray-very-light: #{variables.$color__gray-very-light};
  --color-gray-transparent: #{variables.$color__gray-transparent};
  --color-gray-modal: #{variables.$color__gray-modal};

  --color-white: #{variables.$color__white};
  --color-green: #{variables.$color__green};
  --color-yellow: #{variables.$color__yellow};
  --color-yellow-dark: #{variables.$color__yellow-dark};
  --color-red: #{variables.$color__red};
  --color-red-light: #{variables.$color__red-light};

  --color-blue: #{variables.$color__blue};
  --color-blue-dark: #{variables.$color__blue-dark};

  --gradient-blue: #{variables.$gradient__blue};
}